<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <sat-icon name="athleteBadge" :style="{ fontSize: '2.5em' }" />
      <h5 class="ms-2 lh-1">{{ t("team.current_player") }} ({{ name }})</h5>
    </div>
    <sat-search-input
      class="mb-5"
      :placeHolder="t('team.search_player_in_team')"
      @search="onSearch"
    />
    <slide-team-member
      :teamId="teamId"
      :resultSearch="result"
      :searching="searching"
      :loadingSearch="loadingSearch"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatIcon from "@/components/SatIcon.vue";
import SlideTeamMember from "@/components/team/slideTeamMember.vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { teamService } from "@/_services";

export default defineComponent({
  components: {
    SatSearchInput,
    SatIcon,
    SlideTeamMember,
    LoadingOutlined,
  },
  props: {
    name: String,
    teamId: Number,
  },
  setup(props) {
        const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const state = reactive({
      result: [],
      searching: false,
      loadingSearch: false,
    });

    const { run: getPlayerCurrent } = useRequest(teamService.getPlayerCurrent, {
      manual: true,
    });

const formatResult = (data) => {
      const result = data.map((da) => {
        return {
          id: da?.accounts?.account_id,
          name: {
            en:
              `${da?.accounts?.account_informations?.account_information_firstname_en} ${da?.accounts?.account_informations?.account_information_lastname_en}` ??
              "",
            th:
              `${da?.accounts?.account_informations?.account_information_firstname_th} ${da?.accounts?.account_informations?.account_information_lastname_th}` ??
              "",
          },
          avatar:da?.accounts?.account_informations?.account_media_preview_url,
          age:
            da?.accounts?.account_informations?.account_information_age ?? "-",
          nationallity: {
                th: da?.accounts?.account_informations.account_information_nationality ?? '-',
                en: da?.accounts?.account_informations.account_information_nationality_en ?? '-',
            },
        };
      });
      return result
    };
    const onSearch = async (params) => {
      const { teamId } = props;
      if (params?.name) {
        state.searching = true;
        state.loadingSearch = true
        const { team_player_history } = await getPlayerCurrent(teamId, {
          ...params,
        });
        state.result = formatResult(team_player_history.data)
        state.loadingSearch = false
      } else {
        state.searching = false;
      }
    };

    return {
      t,
      store,
      ...toRefs(state),
      onSearch,
    };
  },
});
</script>

<style lang="less" scoped>
h5 {
  margin: auto 0px;
}
</style>
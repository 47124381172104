import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ef9101a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "staff bg-primary" }
const _hoisted_2 = { class: "min-vh-70 team-wrapper overflow-hidden" }
const _hoisted_3 = { class: "container position-relative container2" }
const _hoisted_4 = { class: "row gx-5 h-100 align-items-center my-5" }
const _hoisted_5 = {
  class: "position-relative align-self-center",
  style: { zIndex: 2 }
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "col-6 text-white" }
const _hoisted_9 = { class: "text-white" }
const _hoisted_10 = { class: "ms-3 text-primary" }
const _hoisted_11 = { class: "fw-light mb-4 text-white" }
const _hoisted_12 = { class: "small" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "historical-info" }
const _hoisted_16 = { class: "container" }
const _hoisted_17 = { class: "small mb-0" }
const _hoisted_18 = { class: "title fw-bold" }
const _hoisted_19 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sat_breadcrumb = _resolveComponent("sat-breadcrumb")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_tab_1 = _resolveComponent("tab-1")!
  const _component_tab_2 = _resolveComponent("tab-2")!
  const _component_tab_3 = _resolveComponent("tab-3")!
  const _component_tab_4 = _resolveComponent("tab-4")!
  const _component_tab_5 = _resolveComponent("tab-5")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_a_spin, {
        spinning: !_ctx.loading
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_sat_breadcrumb, {
              routes: _ctx.routes,
              class: "my-2 breadcrumb"
            }, null, 8, ["routes"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["m-auto position-relative d-flex justify-content-center", _ctx.window.innerWidth > 575 ? `col-5 offset-1` : `col-6`])
              }, [
                _createElementVNode("p", _hoisted_5, [
                  (
                    _ctx.banner?.setting_team_informations
                      ?.setting_team_media_profile_url
                  )
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: 
                    _ctx.banner?.setting_team_informations
                      ?.setting_team_media_profile_url
                  ,
                        class: "img-fluid"
                      }, null, 8, _hoisted_6))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        src: require('@/assets/logo-placeholder.svg'),
                        class: "img-fluid-2"
                      }, null, 8, _hoisted_7))
                ])
              ], 2),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h1", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.banner?.setting_team_informations
                    ?.setting_team_information_name_th || "-") + " ", 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.teams?.setting_team_informations
                      ?.setting_team_information_abbreviation || "-"), 1)
                ]),
                _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.banner?.setting_team_informations
                    ?.setting_team_information_name_en || "-"), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t("team.team_type")), 1),
                _createElementVNode("p", null, [
                  (_ctx.store.state.currentLanguage === 'th')
                    ? (_openBlock(), _createElementBlock("label", _hoisted_13, _toDisplayString(_ctx.banner?.global_config_local_types
                    ?.global_config_local_type_name_th || "-"), 1))
                    : (_openBlock(), _createElementBlock("label", _hoisted_14, _toDisplayString(_ctx.banner?.global_config_local_types
                    ?.global_config_local_type_name_en || "-"), 1))
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _createElementVNode("section", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.activeKey,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
          "tab-bar-gutter": 50
        }, {
          renderTabBar: _withCtx(({ DefaultTabBar, ...props }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(DefaultTabBar), _mergeProps(props, { style: {
                zIndex: 1,
                background: 'transparent',
                textAlign: 'center',
                color: '#fff',
              } }), null, 16))
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs[_ctx.store.state.currentLanguage], (tab, idx) => {
              return (_openBlock(), _createBlock(_component_a_tab_pane, { key: idx }, {
                tab: _withCtx(() => [
                  _createElementVNode("p", _hoisted_17, _toDisplayString(tab.type), 1),
                  _createElementVNode("p", _hoisted_18, _toDisplayString(tab.title), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_card, null, {
                    default: _withCtx(() => [
                      (idx === 0)
                        ? (_openBlock(), _createBlock(_component_tab_1, {
                            key: 0,
                            firstTab: _ctx.firstTab,
                            teamId: _ctx.teamId,
                            routes: _ctx.routes
                          }, null, 8, ["firstTab", "teamId", "routes"]))
                        : (idx === 1)
                          ? (_openBlock(), _createBlock(_component_tab_2, {
                              key: 1,
                              teamId: _ctx.teamId,
                              banner: _ctx.banner
                            }, null, 8, ["teamId", "banner"]))
                          : (idx === 2)
                            ? (_openBlock(), _createBlock(_component_tab_3, {
                                key: 2,
                                teamId: _ctx.teamId,
                                banner: _ctx.banner
                              }, null, 8, ["teamId", "banner"]))
                            : (idx === 3)
                              ? (_openBlock(), _createBlock(_component_tab_4, {
                                  key: 3,
                                  teamId: _ctx.teamId
                                }, null, 8, ["teamId"]))
                              : (idx === 4)
                                ? (_openBlock(), _createBlock(_component_tab_5, {
                                    key: 4,
                                    teamId: _ctx.teamId
                                  }, null, 8, ["teamId"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                    _createVNode(_component_a_empty)
                                  ]))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeKey"])
      ])
    ])
  ]))
}
<template>
  <a-button type="link" size="large" :onClick="onClickButton">
    <sat-icon
      name="filterIcon"
      style="
         {
          width: 32px;
        }
      "
    />
  </a-button>
  <a-modal
    centered
    v-model:visible="visibleModal"
    :title="null"
    :footer="null"
    :closable="false"
    :width="820"
  >
    <div class="d-flex align-items-center justify-content-between mb-3">
      <a-button danger @click="reset">
        <template #icon>
          <DeleteOutlined />
        </template>
        {{ t("label.search.clear") }}
      </a-button>
      <div>
        <a-button type="link" class="text-muted me-1" @click="onCloseModal"
          >{{ t("label.search.cancel") }}</a-button
        >
        <a-button class="bg-success border-success text-white" @click="onSubmit"
          >{{ t("label.search.search") }}</a-button
        >
      </div>
    </div>
    <div>
      <p>{{ t("label.search.age") }}</p>
      <div class="mt-4 mb-3">
        <!-- <a-slider
          range
          :tooltip-visible="visibleModal"
          v-model:value="ageRange"
        /> -->
        <Slider v-model="ageRange" />
      </div>
      <p>{{t('athletes.nationality')}}</p>
      <div class="mb-3">
        <nationality-selector
          v-model:value="nationalityStatus"
          @selected="handleNationalChange"
        ></nationality-selector>
      </div>
      <p>{{t('label.search.contract')}}</p>
      <div class="mb-3">
        <a-button
          :onClick="() => onClickContract(item.value)"
          v-for="item in contractStatus"
          :key="item.value"
          :type="item.isActive ? 'primary' : ''"
          class="button-group"
          size="large"
        >
          <p>{{ item.label[store.state.currentLanguage] }}</p>
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from "vue";
import SatIcon from "@/components/SatIcon.vue";
import NationalitySelector from "@/components/selector/NationalitySelector.vue";
import Slider from "@vueform/slider";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  components: {
    SatIcon,
    NationalitySelector,
    Slider
  },
  emits: ["onSubmit"],
  props: ["type"],
  setup(props, { emit }) {
    const { type } = props;
       const { t,locale } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const state = reactive({
      visibleModal: false,
      nationalityStatus: null,
      contractStatus: [
        { label: {th:"ทั้งหมด",en:"All"}, value: "null", isActive: true },
        { label: {th:"ประจำ",en:"Permanent"}, value: "false", isActive: false },
        { label: {th:"ยืมตัว",en:"Loan Contract"}, value: "true", isActive: false },
      ],
      contract: "null",
      ageRange: [0,100],
    });
    const reset = () => {
      state.nationalityStatus = null;
      state.contractStatus = [
         { label: {th:"ทั้งหมด",en:"All"}, value: "null", isActive: true },
        { label: {th:"ประจำ",en:"Permanent"}, value: "false", isActive: false },
        { label: {th:"ยืมตัว",en:"Loan Contract"}, value: "true", isActive: false },
      ];
      state.contract = "null";
      state.ageRange = [0,100];
    };

    const onClickContract = (value) => {
      state.contractStatus = state.contractStatus.map((button) => {
        if (button.value === value) {
          button.isActive = true;
        } else {
          button.isActive = false;
        }
        return button;
      });
      state.contract = value;
    };
    const onClickButton = () => {
      state.visibleModal = !state.visibleModal;
    };

    const onCloseModal = () => {
      state.visibleModal = false;
    };
    const onSubmit = () => {
      const loan =
        state.contract === "true"
          ? true
          : state.contract === "false"
          ? false
          : "";
      if (type) {
        emit("onSubmit", {
          nationality:
            state.nationalityStatus === "all" ? "" : state.nationalityStatus,
          team_player_history_loan: loan,
          maxAge: state.ageRange[1],
          minAge: state.ageRange[0],
          type,
          page: 1,
          size: 5,
          isFilter: true,
        });
      }

      onCloseModal();
    };
    return {
      t,
      store,
      ...toRefs(state),
      onClickButton,
      onCloseModal,
      reset,
      onSubmit,
      onClickContract,
    };
  },
};
</script>

<style>
.button-group {
  border-radius: 10px;
  margin-right: 10px;
}
</style>
<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <!-- <sat-icon name="athleteBadge" :style="{ fontSize: '2.5em' }" /> -->
      <h5 class="ms-2 lh-1">{{ t("team.important_competition") }}</h5>
    </div>
    <sat-search-input
      advance-search="team-competition"
      color="#0F4C82"
      class="mb-5"
      :placeHolder="t('team.search_competition')"
      @search="onFetchTeamsMatchHistoryImportant"
    />
    <div>
      <div v-if="loadingImportant" class="loading-container">
        <LoadingOutlined />
      </div>
      <a-row
        v-else-if="historyImportant?.data.length > 0"
        :gutter="[15, 20]"
        align="right"
      >
        <a-col v-for="item in historyImportant?.data" :key="item">
          <div class="competition-container">
            <div class="top">
              <a-row>
                <a-col span="8">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    "
                  >
                    <img
                      v-if="
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_team_image
                      "
                      :src="
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_team_image
                      "
                      alt="team-logo"
                    />
                    <img
                      v-else
                      src="../../assets/logo-placeholder-brown.svg"
                      alt="team-logo"
                    />
                  </div>
                </a-col>
                <a-col span="8">
                  <a-typography-text
                    class="paragraph"
                    style="
                      text-decoration-line: underline;
                      color: #0f4c82;
                      width: 100px;
                    "
                    :ellipsis="{
                      tooltip:
                        store.state.currentLanguage === 'th'
                          ? item?.setting_sub_competition
                              ?.setting_sub_competition_name_th
                          : item?.setting_sub_competition
                              ?.setting_sub_competition_name_en,
                      rows: 1,
                    }"
                    :content="
                      store.state.currentLanguage === 'th'
                        ? item?.setting_sub_competition
                            ?.setting_sub_competition_name_th
                        : item?.setting_sub_competition
                            ?.setting_sub_competition_name_en
                    "
                  />
                  <!-- <p
                    style="
                      text-decoration-line: underline;
                      color: #0f4c82;
                      height: 40px;
                    "
                  >
                    {{
                      item?.setting_sub_competition
                        ?.setting_sub_competition_name_th || "-"
                    }}
                  </p> -->
                  <h5 style="color: #0f4c82; font-weight: 600" v-if="item.versus_match_team_detail_one_id.versus_match_score !== null || item.versus_match_team_detail_two_id.versus_match_score !== null">

                    {{
                      `${item?.versus_match_team_detail_one_id?.versus_match_score }-${item?.versus_match_team_detail_two_id?.versus_match_score}`
                    }}
                  </h5>
                  <h5 style="color: #0f4c82; font-weight: 600"  v-else>-</h5>
                </a-col>
                <a-col span="8">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    "
                  >
                    <img
                      v-if="
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_team_image
                      "
                      :src="
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_team_image
                      "
                      alt="team-logo"
                    />
                    <img
                      v-else
                      src="../../assets/logo-placeholder-brown.svg"
                      alt="team-logo"
                    />
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col span="8">
                  <p class="sub-text">
                    {{
                      store.state.currentLanguage === "th"
                        ? item?.versus_match_team_detail_one_id
                            ?.versus_match_team_name || "-"
                        : item?.versus_match_team_detail_one_id
                            ?.versus_match_team_name_en || "-"
                    }}
                  </p>
                </a-col>
                <a-col span="8">
                  <p
                    :class="`${
                      Helpers.scoreResult(
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_score,
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_score
                      ).classStyle
                    } sub-text`"
                    style="font-weight: 600"
                  >
                    {{
                      Helpers.scoreResult(
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_score,
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_score
                      )[store.state.currentLanguage] || "-"
                    }}
                  </p>
                </a-col>
                <a-col span="8">
                  <p class="sub-text">
                    {{
                      store.state.currentLanguage === "th"
                        ? item?.versus_match_team_detail_two_id
                            ?.versus_match_team_name || "-"
                        : item?.versus_match_team_detail_two_id
                            ?.versus_match_team_name_en || "-"
                    }}
                  </p>
                </a-col>
              </a-row>
            </div>
            <a-divider class="divider-style" />
            <div class="bottom">
              <div style="display: flex; justify-content: space-between">
                <a-typography-text
                  class="paragraph"
                  style="width: 150px"
                  :ellipsis="{
                    tooltip:
                      store.state.currentLanguage === 'th'
                        ? item?.setting_stadiums?.setting_stadium_name_th
                        : item?.setting_stadiums?.setting_stadium_name_en,
                    rows: 1,
                  }"
                  :content="
                    store.state.currentLanguage === 'th'
                      ? item?.setting_stadiums?.setting_stadium_name_th
                      : item?.setting_stadiums?.setting_stadium_name_en
                  "
                />
                <!-- <span style="text-decoration-line: underline; color: #0f4c82">
                  {{ item?.setting_stadiums?.setting_stadium_name_th }}</span
                > -->
                <span>{{ Helpers.formatDate(item?.versus_match_date) }}</span>
              </div>
              <a-typography-text
                v-if="
                  item.versus_match_team_detail_one_id.versus_match_team_id ===
                  teamId
                "
                :ellipsis="{
                  tooltip:
                    store.state.currentLanguage === 'th'
                      ? item.versus_match_team_detail_one_id
                          .versus_match_team_detail_remark
                      : item.versus_match_team_detail_one_id
                          .versus_match_team_detail_remark_en,
                  rows: 3,
                }"
                :content="
                  store.state.currentLanguage === 'th'
                    ? item.versus_match_team_detail_one_id
                        .versus_match_team_detail_remark || 'ไม่มีข้อมูล'
                    : item.versus_match_team_detail_one_id
                        .versus_match_team_detail_remark_en || 'No data'
                  // item.versus_match_team_detail_one_id
                  //   .versus_match_team_detail_remark
                  //   ? item.versus_match_team_detail_one_id
                  //       .versus_match_team_detail_remark
                  //   : 'ไม่มีข้อมูล'
                "
                style="margin-top: 10px"
              />
              <p
                v-else-if="
                  item.versus_match_team_detail_two_id.versus_match_team_id ===
                  teamId
                "
                style="margin-top: 10px"
              >
                {{
                  store.state.currentLanguage === "th"
                    ? item.versus_match_team_detail_two_id
                        .versus_match_team_detail_remark || 'ไม่มีข้อมูล'
                    : item.versus_match_team_detail_two_id
                        .versus_match_team_detail_remark_en || 'No data'

                }}
              </p>
              <p
                style="
                  text-align: right;
                  text-decoration-line: underline;
                  color: #0f4c82;
                  cursor: pointer;
                  margin: 0px;
                "
                :onClick="() => onClickDetailMore(item)"
              >
                {{ t("team.see_more") }}
              </p>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else />
      <a-divider />
      <div class="pagination-container">
        <p>
          {{
            `1-${historyImportant?.perPage} ${t("label.table.of")} ${historyImportant?.total} ${t("label.table.items")}`
          }}
        </p>
        <a-pagination
          :current="currentPageHistoryImportant"
          :page-size-options="pageSizeOptions"
          :total="historyImportant?.total"
          show-size-changer
          :page-size="historyImportant?.perPage"
          @change="onPageChangeHistoryImportant"
          @showSizeChange="onShowSizeChangeHistoryImportant"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== historyImportant?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
  <div style="margin-top: 20px">
    <div class="d-flex align-items-center mb-3">
      <!-- <sat-icon name="athleteBadge" :style="{ fontSize: '2.5em' }" /> -->
      <h5 class="ms-2 lh-1">{{ t("team.list_competition") }}</h5>
    </div>
    <sat-search-input
      advance-search="team-competition"
      color="#0F4C82"
      class="mb-5"
      :placeHolder="t('team.search_competition')"
      @search="onFetchTeamsMatchHistory"
    />
    <div>
      <div v-if="loading" class="loading-container">
        <LoadingOutlined />
      </div>
      <a-row
        v-else-if="history?.data.length > 0"
        :gutter="[15, 20]"
        align="right"
      >
        <a-col v-for="item in history?.data" :key="item">
          <div class="competition-container">
            <div class="top">
              <a-row>
                <a-col span="8">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    "
                  >
                    <img
                      v-if="
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_team_image
                      "
                      :src="
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_team_image
                      "
                      alt="team-logo"
                    />
                    <img
                      v-else
                      src="../../assets/logo-placeholder-brown.svg"
                      alt="team-logo"
                    />
                  </div>
                </a-col>
                <a-col span="8">
                  <a-typography-text
                    class="paragraph"
                    style="
                      text-decoration-line: underline;
                      color: #0f4c82;
                      width: 100px;
                    "
                    :ellipsis="{
                      tooltip:
                        store.state.currentLanguage === 'th'
                          ? item?.setting_sub_competition
                              ?.setting_sub_competition_name_th
                          : item?.setting_sub_competition
                              ?.setting_sub_competition_name_en,
                      rows: 1,
                    }"
                    :content="
                      store.state.currentLanguage === 'th'
                        ? item?.setting_sub_competition
                            ?.setting_sub_competition_name_th
                        : item?.setting_sub_competition
                            ?.setting_sub_competition_name_en
                    "
                  />
                  <!-- <p
                    style="
                      text-decoration-line: underline;
                      color: #0f4c82;
                      height: 40px;
                    "
                  >
                    {{
                      item?.setting_sub_competition
                        ?.setting_sub_competition_name_th || "-"
                    }}
                  </p> -->
                  <h5 style="color: #0f4c82; font-weight: 600"  v-if="item.versus_match_team_detail_one_id.versus_match_score !== null || item.versus_match_team_detail_two_id.versus_match_score !== null">
                    {{
                      `${item?.versus_match_team_detail_one_id?.versus_match_score}-${item?.versus_match_team_detail_two_id?.versus_match_score}`
                    }}
                  </h5>
                                    <h5 style="color: #0f4c82; font-weight: 600"  v-else>-</h5>

                </a-col>
                <a-col span="8">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    "
                  >
                    <img
                      v-if="
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_team_image
                      "
                      :src="
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_team_image
                      "
                      alt="team-logo"
                    />
                    <img
                      v-else
                      src="../../assets/logo-placeholder-brown.svg"
                      alt="team-logo"
                    />
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col span="8">
                  <p class="sub-text">
                    {{
                      store.state.currentLanguage === "th"
                        ? item?.versus_match_team_detail_one_id
                            ?.versus_match_team_name || "-"
                        : item?.versus_match_team_detail_one_id
                            ?.versus_match_team_name_en || "-"
                    }}
                  </p>
                </a-col>
                <a-col span="8">
                  <p
                    :class="`${
                      Helpers.scoreResult(
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_score,
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_score
                      ).classStyle
                    } sub-text`"
                    style="font-weight: 600"
                  >
                    {{
                      Helpers.scoreResult(
                        item?.versus_match_team_detail_one_id
                          ?.versus_match_score,
                        item?.versus_match_team_detail_two_id
                          ?.versus_match_score
                      )[store.state.currentLanguage] || "-"
                    }}
                  </p>
                </a-col>
                <a-col span="8">
                  <p class="sub-text">
                    {{
                      store.state.currentLanguage === "th"
                        ? item?.versus_match_team_detail_two_id
                            ?.versus_match_team_name || "-"
                        : tem?.versus_match_team_detail_two_id
                            ?.versus_match_team_name_en || "-"
                    }}
                  </p>
                </a-col>
              </a-row>
            </div>
            <a-divider class="divider-style" />
            <div class="bottom">
              <div style="display: flex; justify-content: space-between">
                <a-typography-text
                  class="paragraph"
                  style="width: 150px"
                  :ellipsis="{
                    tooltip:
                      store.state.currentLanguage === 'th'
                        ? item?.setting_stadiums?.setting_stadium_name_th
                        : item?.setting_stadiums?.setting_stadium_name_en,
                    rows: 1,
                  }"
                  :content="
                    store.state.currentLanguage === 'th'
                      ? item?.setting_stadiums?.setting_stadium_name_th
                      : item?.setting_stadiums?.setting_stadium_name_en
                  "
                />
                <!-- <span style="text-decoration-line: underline; color: #0f4c82">
                  {{ item?.setting_stadiums?.setting_stadium_name_th }}
                </span> -->
                <span>{{ Helpers.formatDate(item?.versus_match_date) }}</span>
              </div>
              <a-typography-text
                v-if="
                  item.versus_match_team_detail_one_id.versus_match_team_id ===
                  teamId
                "
                :ellipsis="{
                  tooltip:
                    store.state.currentLanguage === 'th'
                      ? item.versus_match_team_detail_one_id
                          .versus_match_team_detail_remark
                      : item.versus_match_team_detail_one_id
                          .versus_match_team_detail_remark_en,
                  rows: 3,
                }"
                :content="
                  store.state.currentLanguage === 'th'
                    ? item.versus_match_team_detail_one_id
                        .versus_match_team_detail_remark || 'ไม่มีข้อมูล'
                    : item.versus_match_team_detail_one_id
                        .versus_match_team_detail_remark_en || 'No data'
                "
                style="margin-top: 10px"
              />
              <p
                v-else-if="
                  item.versus_match_team_detail_two_id.versus_match_team_id ===
                  teamId
                "
                style="margin-top: 10px"
              >
                {{
                  store.state.currentLanguage === "th"
                    ? item.versus_match_team_detail_two_id
                        .versus_match_team_detail_remark || 'ไม่มีข้อมูล'
                    : item.versus_match_team_detail_two_id
                        .versus_match_team_detail_remark_en || 'No data'
                }}
              </p>
              <div style="display: flex; justify-content: right">
                <p
                  style="
                    text-decoration-line: underline;
                    color: #0f4c82;
                    cursor: pointer;
                    margin: 0px;
                    width: 60px;
                  "
                  :onClick="() => onClickDetailMore(item)"
                >
                  {{ t("team.see_more") }}
                </p>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else />
      <a-divider />
      <div class="pagination-container">
        <p>
          {{ `1-${history?.perPage} ${t("label.table.of")} ${history?.total} ${t("label.table.items")}` }}
        </p>
        <a-pagination
          :current="currentPageHistory"
          :page-size-options="pageSizeOptions"
          :total="history?.total"
          show-size-changer
          :page-size="history?.perPage"
          @change="onPageChangeHistory"
          @showSizeChange="onShowSizeChangeHistory"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== history?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SatSearchInput from "@/components/SatSearchInput.vue";
import SatIcon from "@/components/SatIcon.vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { onMounted, reactive, toRefs } from "vue";
import { useRequest } from "vue-request";
import { teamService } from "@/_services";
import { Helpers } from "@/_modules/helpers";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  components: {
    SatSearchInput,
    SatIcon,
    LoadingOutlined,
  },
  props: {
    teamId: {
      type: String,
      require: false,
    },
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const { teamId } = props;
    const state = reactive({
      historyImportant: {},
      history: {},
      pageImportant: 1,
      page: 1,
      pageSizeOptions: ["8", "20", "40"],
      currentPageHistoryImportant: 1,
      currentPageHistory: 1,
      loadingImportant: true,
      loading: true,
      query: null,
      queryImportant: null,
    });

    const { run: getTeamsMatchHistoryImportant } = useRequest(
      teamService.getTeamsMatchHistoryImportant,
      { manual: true }
    );

    const { run: getTeamsMatchHistory } = useRequest(
      teamService.getTeamsMatchHistory,
      { manual: true }
    );

    onMounted(() => {
      try {
        onFetchTeamsMatchHistoryImportant({
          page: state.pageImportant,
          size: state.pageSizeOptions[0],
        });
        onFetchTeamsMatchHistory({
          page: state.page,
          size: state.pageSizeOptions[0],
        });
      } catch (error) {
        // console.log("tab4 catch :: ", state);
      } finally {
        // console.log("tab4 finally :: ", { state, Helpers });
      }
    });

    const onFetchTeamsMatchHistoryImportant = async (params) => {
      const { isFilter, ...other } = params;
      if (isFilter) {
        state.query = other;
      }
      state.loadingImportant = true;
      const res = await getTeamsMatchHistoryImportant(teamId, {
        ...state.query,
        ...other,
      });
      state.historyImportant = res.versus_match;
      state.loadingImportant = false;
    };

    const onFetchTeamsMatchHistory = async (params) => {
      const { isFilter, ...other } = params;
      if (isFilter) {
        state.queryImportant = other;
      }
      state.loading = true;
      const res = await getTeamsMatchHistory(teamId, {
        ...state.queryImportant,
        ...other,
      });
      state.history = res.versus_match;
      state.loading = false;
    };

    const onPageChangeHistoryImportant = (page, pageSize) => {
      state.currentPageHistoryImportant = page;
      onFetchTeamsMatchHistoryImportant({ page, size: pageSize });
    };

    const onShowSizeChangeHistoryImportant = (current, pageSize) => {
      state.currentPageHistoryImportant = current;
      onFetchTeamsMatchHistoryImportant({ page: current, size: pageSize });
    };

    const onPageChangeHistory = (page, pageSize) => {
      state.currentPageHistory = page;
      onFetchTeamsMatchHistory({ page, size: pageSize });
    };

    const onShowSizeChangeHistory = (current, pageSize) => {
      state.currentPageHistory = current;
      onFetchTeamsMatchHistory({ page: current, size: pageSize });
    };

    const onClickDetailMore = (item) => {
      // '/competitions/:id/:subId/:competitionId/:versusId',
      router.push(
        `/competitions/${item.setting_competition_id}/${item.setting_sub_competition.id}/${item.competition_section_id}/${item.id}`
      );
      // console.log("onClickDetailMore :: ", {
      //   subCompetitionId: item.setting_sub_competition.id,
      //   versusId: item.id,
      // });
    };

    return {
      t,
      store,
      ...toRefs(state),
      Helpers,
      teamId,
      onPageChangeHistoryImportant,
      onShowSizeChangeHistoryImportant,
      onPageChangeHistory,
      onShowSizeChangeHistory,
      onClickDetailMore,
      onFetchTeamsMatchHistoryImportant,
      onFetchTeamsMatchHistory,
    };
  },
};
</script>

<style lang="less" scoped>
h5 {
  margin: 5px 0px;
}
.sub-text {
  font-size: 12px;
}
.paragraph {
  margin: auto 0px;
}
.competition-container {
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.top,
.bottom {
  padding: 10px;
}
.top {
  text-align: center;
  height: 140px;
  img {
    width: 60px;
  }
}
.divider-style {
  margin: 0px;
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  p {
    margin: auto 0px;
  }
}
</style>
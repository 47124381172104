<template>
  <team-member
    :teamId="teamId"
    :name="
      store.state.currentLanguage === 'th'
        ? banner?.global_config_local_types?.global_config_local_type_name_th
        : banner?.global_config_local_types?.global_config_local_type_name_en
    "
  />
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue";
import TeamMember from "@/views/team/TeamMember.vue";
import { useStore } from "vuex";

export default {
  name: "Tab2",
  props: {
    teamId: {
      type: Number,
      require: true,
    },
    banner: {
      type: Object,
    },
  },
  components: {
    LoadingOutlined,
    TeamMember,
  },
  setup(props) {
    const store = useStore();
    const { banner } = props;
    return {
      store,
      banner,
    };
  },
};
</script>

<style>
</style>